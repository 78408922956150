export const environment = {
  production: false,
  mode: 'dev',
  version: '1.0.0',
  service: {
    host: 'https://apigw-dev.xbaht.com',
  },
  webHost: {
    iservice: 'https://iservice-dev.xbaht.com',
  },
  security: {
    encryption: true,
    publicKey: `-----BEGIN PUBLIC KEY-----
    MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHGygQr2vnJwW0giG/Lhri6h0Kog
    W9YLGI+nEq+TvuWGqiTPSwYhCqZRVLUeqBOjiStpJra68g8TepR48Bpykcx0Bz7B
    7z59xH0Wp3KxrL7LcOjOVevVKdLAbNduDgV9ZMRRfo31wqHEhcbjOgPHeis5jFax
    CipVPvf07JcQzJIPAgMBAAE=
    -----END PUBLIC KEY-----`,
  },
  google: {
    ga: '',
  },
  appLink: {
    appStore: 'https://apps.apple.com/th/app/xbaht/id1445306005',
    playStore:
      'https://play.google.com/store/apps/details?id=com.xbaht.android',
  },
  socialLink: {
    facebook: 'https://www.facebook.com/xbaht.se',
    line: 'https://social-plugins.line.me/lineit/share?url=https://www.xbaht.com',
    youtube: 'https://youtu.be/lhSUQrvi6lA',
    email: 'mailto:info@xbaht.com',
  },
  otherLink: {
    complain:
      'https://harvest.whistleportal.eu/WhistleBlower/Form/218-465-6fe20f83b1a048c69cde55d134398304',
  },
};